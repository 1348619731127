import { SagaIterator } from 'redux-saga';
import {
    call,
    takeEvery,
    put,
    spawn,
} from 'redux-saga/effects';
import { setupAnalytics } from '../analytics';
import { StudioFile, updateStudioFile } from './reducer';
import * as LoadableState from '../loadableState';
import fetchExperienceFile from '../../../functions/fetchExperienceFile';
import { loadCustomComponentScriptsSaga } from '../customComponents';
import { isStudioPreviewSource } from '../../../functions/previewMode';

export const LOAD_STUDIO_FILE = 'STUDIO_FILE/LOAD_STUDIO_FILE' as const;

export type LoadStudioFileAction = {
    type: typeof LOAD_STUDIO_FILE;
    id: string | null;
}

export const loadStudioFile = (id: string | null): LoadStudioFileAction => ({
    type: LOAD_STUDIO_FILE,
    id,
});

export function* loadStudioFileSaga({ id }: LoadStudioFileAction): SagaIterator<void> {
    try {
        yield put(updateStudioFile(LoadableState.Loading));
        if (!id) { throw Error('No studio id'); }
        const studioFile = yield call(fetchExperienceFile, id);
        if (
            !__IS_PREVIEW__
            && studioFile.is_template
            && !isStudioPreviewSource()
        ) { throw Error('No studio file found'); }

        yield call(loadCustomComponentScriptsSaga, studioFile as StudioFile);
        yield put(updateStudioFile(LoadableState.Ready({ id, ...studioFile })));
        if (__IS_PREVIEW__) { return; }

        // setupAnalytics might wait for user consent but we
        // don't want loadStudioFileSaga to block finishing initialization
        yield spawn(setupAnalytics);
    } catch (e) {
        yield put(updateStudioFile(
            LoadableState.Err('Error loading studio file'),
        ));
    }
}

function* saga(): SagaIterator<void> {
    yield takeEvery(LOAD_STUDIO_FILE, loadStudioFileSaga);
}

export default saga;
