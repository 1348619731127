import * as React from 'react';
import styled, { ThemeProvider, DefaultTheme } from 'styled-components';
import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import Widget from '../shared/widget';
import { FormContext, FormContextType } from './formContext';
import { cleanLink } from '../../functions/clean';
import {
    useMacroScope,
    makeUseMacros,
    RuntimePromise,
    MacroContext,
} from '../shared/macroHelpers';
import {
    getForm,
    formTransitionStatus,
    FormStatus,
    FORM_STATUS_DONE,
    FORM_STATUS_SETUP,
    FORM_STATUS_INITIAL,
    FORM_STATUS_REDIRECT,
} from '../../store/processes/forms';
import { fireAction } from '../../store/processes/actions';
import {
    ACTION_TYPE_FORM_SUBMIT,
} from '../../store/processes/studioFile/componentConfigs/shared/action';
import {
    ANALYTICS_DEFAULT,
} from '../../store/processes/studioFile/componentConfigs/shared/analytics';
import {
    FormOptions,
    FormComponentLists,
    FormStylingOptions,
    FORM_SUBMIT_NONE,
} from '../../store/processes/studioFile/componentConfigs/form';
import ComponentList from '../shared/componentList';
import { State } from '../../store/processes';

const FORM_COMPONENT_NAME = 'Form';

const useMacros = makeUseMacros(FORM_COMPONENT_NAME, {
    action: {},
});

type FormProps = {
    id: string;
    typeId: string;
    options: FormOptions;
    componentLists: FormComponentLists;
    macroContext: MacroContext;
};

const FormWrapper = styled.div`
    padding: ${(props): string => (props.theme.showWidget ? '16px' : '0')};
`;

const FormHeader = styled.h2`
    color: #3b3f47;
    font-size: 24px;
    font-weight: 300;
    padding-bottom: 16px;
`;

const addFormTheme = (formTheme: FormStylingOptions) => (theme: DefaultTheme): DefaultTheme => ({
    ...theme,
    formTheme,
    showWidget: false,
});

const makeOnSubmit = (id: string, typeId: string, dispatch: Dispatch) => (
    (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        dispatch(fireAction({
            action: ACTION_TYPE_FORM_SUBMIT,
            actionAnalytics: {
                type: ANALYTICS_DEFAULT,
            },
            meta: {
                component: FORM_COMPONENT_NAME,
                firingComponentId: id,
                firingComponentType: {
                    name: FORM_COMPONENT_NAME,
                    id: typeId,
                },
                formId: id,
            },
        }));
    }
);

const Form = ({
    id,
    typeId,
    options,
    componentLists,
    macroContext,
}: FormProps): React.ReactElement | null => {
    // Get Redux Form State
    const form = useSelector((state: State) => getForm(state, id));

    const macroSupportedOptions = React.useMemo(() => ({
        action: (
            options.submissionType === FORM_SUBMIT_NONE
                ? ''
                : options.action ?? ''
        ),
    }), [options]);

    // Render Action Macros
    const macroScope = useMacroScope(macroContext);
    const macroPromise = useMacros(macroScope, macroSupportedOptions);
    const actionPromise = RuntimePromise.map(({ action }) => action, macroPromise);

    // Create form context for children components
    // memoized to prevent rerendering all children
    // when the object is recreated with the same ID
    const formContext: FormContextType = React.useMemo(
        () => ({ formId: id }),
        [id],
    );

    const formStatus = form?.status ?? null;
    const dispatch = useDispatch();
    const submit = React.useMemo(
        () => {
            const submitDisabled = ([
                null,
                FORM_STATUS_DONE,
                FORM_STATUS_SETUP,
            ] as Array<FormStatus | null>).includes(formStatus);

            if (submitDisabled) {
                return undefined;
            }

            return makeOnSubmit(id, typeId, dispatch);
        },
        [dispatch, formStatus, id, typeId],
    );

    // Setup should begin as soon as form starts rendering
    if (!form) {
        dispatch(formTransitionStatus(id, FORM_STATUS_SETUP));
    }

    // Wait until children render before switching to INITIAL state
    React.useEffect(() => {
        if (form && form.status === FORM_STATUS_SETUP) {
            dispatch(formTransitionStatus(id, FORM_STATUS_INITIAL));
        }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [dispatch, form?.status, id]);

    // When form is ready for HTML submit trigger submit on the form element
    // note triggering submit in js bypasses to onSubmit form callback
    const formRef = React.createRef<HTMLFormElement>();
    React.useEffect(() => {
        if (form && form.status === FORM_STATUS_REDIRECT) {
            formRef?.current?.submit();
            // Some browsers stay on the page if the server responds with a 204
            // Hubspot takes advantage of this to make HTML form submits stay on the page
            dispatch(formTransitionStatus(id, FORM_STATUS_DONE));
        }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [form?.status, formRef]);

    // Form has not begun setup
    if (!form) {
        return null;
    }

    // Form has completed
    if (formStatus === FORM_STATUS_DONE) {
        return null;
    }

    return (
        <Widget>
            <FormWrapper>
                <form
                    id={`componentId-${id}`}
                    ref={formRef}
                    onSubmit={submit}
                    /* eslint-disable react/jsx-props-no-spreading */
                    {...(
                        options.submissionType === FORM_SUBMIT_NONE
                            ? {}
                            : {
                                action: cleanLink(RuntimePromise.unwrapOr('', actionPromise)),
                                method: options.method,
                            }
                    )}
                    /* eslint-enable react/jsx-props-no-spreading */
                >
                    {
                        options.title
                            ? (
                                <FormHeader>{options.title}</FormHeader>
                            )
                            : null
                    }
                    <FormContext.Provider value={formContext}>
                        <ThemeProvider theme={addFormTheme(options)}>
                            <ComponentList id={componentLists.body} />
                        </ThemeProvider>
                    </FormContext.Provider>
                </form>
            </FormWrapper>
        </Widget>
    );
};

export default Form;
