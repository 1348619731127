import * as React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
    getForm,
    formSetAttribute,
    FORM_STATUS_SETUP,
} from '../../store/processes/forms';
import {
    useMacroScope,
    useMacro,
    RuntimePromise,
    MacroContext,
} from '../shared/macroHelpers';
import {
    InputOptions,
} from '../../store/processes/studioFile/componentConfigs/input';
import { FormLabel } from './shared';
import { State } from '../../store/processes';

export const FormInput = styled.input`
    box-sizing: border-box;
    border-style: solid;
    background-color: ${({ theme }): string => theme?.formTheme?.inputBackgroundColor ?? '#fff'};
    width: ${({ theme }): string => theme?.formTheme?.inputWidth ?? '100%'};
    padding: ${({ theme }): string => theme?.formTheme?.inputPadding ?? '6px'};
    margin: ${({ theme }): string => theme?.formTheme?.inputMargins ?? '0 0 12px'};
    font-size: ${({ theme }): string => theme?.formTheme?.inputFontSize ?? '14px'};
    font-weight: ${({ theme }): string => theme?.formTheme?.inputFontWeight ?? '400'};
    color: ${({ theme }): string => theme?.formTheme?.inputTextColor ?? '#000'};
    text-align: ${({ theme }): string => theme?.formTheme?.inputTextAlign ?? 'left'};
    border-width: ${({ theme }): string => theme?.formTheme?.inputBorderWidth ?? '1px'};
    border-radius: ${({ theme }): string => theme?.formTheme?.inputBorderRadius ?? '1px'};
    border-color: ${({ theme }): string => theme?.formTheme?.inputBorderColor ?? '#ddd'};
`;

const componentName = 'Input';

type InputProps = {
    id: string;
    options: InputOptions;
    macroContext: MacroContext;
};

const Input = ({
    id,
    options,
    macroContext,
}: InputProps): React.ReactElement => {
    const scope = useMacroScope(macroContext);
    const label = RuntimePromise.unwrapOr('', useMacro(
        options.label ?? '',
        scope,
        {
            componentName,
            optionName: 'label',
        },
    ));
    const placeholder = RuntimePromise.unwrapOr('', useMacro(
        options.placeholder ?? '',
        scope,
        {
            componentName,
            optionName: 'placeholder',
        },
    ));
    const defaultValuePromise = useMacro(
        options.defaultValue ?? '',
        scope,
        {
            componentName,
            optionName: 'defaultValue',
        },
    );

    const form = useSelector((state: State) => getForm(state, macroContext.formId ?? '-1'));
    const dispatch = useDispatch();

    const setValue = React.useCallback(
        (val: string): void => {
            if (form) {
                dispatch(formSetAttribute(
                    form.id,
                    options.name,
                    RuntimePromise.Success(val),
                    options.formValidations,
                ));
            }
        },
        [dispatch, form, options.formValidations, options.name],
    );

    React.useEffect(() => {
        if (form?.status === FORM_STATUS_SETUP) {
            setValue(RuntimePromise.unwrapOr('', defaultValuePromise));
        }
    }, [defaultValuePromise, form, setValue]);

    const value = RuntimePromise.unwrapOr(
        '',
        form?.data[options.name] ?? defaultValuePromise,
    );

    const htmlId = React.useMemo(() => `input-${id}`, [id]);
    return (
        <>
            <FormLabel htmlFor={htmlId}>{label}</FormLabel>
            <FormInput
                id={htmlId}
                type="text"
                name={options.name}
                value={String(value)}
                placeholder={placeholder}
                onChange={(e): void => setValue(e.target.value)}
            />
        </>
    );
};

export default Input;
