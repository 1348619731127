import * as React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
    getForm,
    formSetAttribute,
    FORM_STATUS_SETUP,
} from '../../store/processes/forms';
import {
    useMacroScope,
    useMacro,
    RuntimePromise,
    MacroContext,
} from '../shared/macroHelpers';
import {
    CheckboxOptions,
} from '../../store/processes/studioFile/componentConfigs/checkbox';
import { FormLabel } from './shared';
import { State } from '../../store/processes';

const getTopValue = (vals: string): string => {
    const allVals = vals.split(' ');
    switch (allVals.length) {
        default:
            return allVals[0];
    }
};

const getBottomValue = (vals: string): string => {
    const allVals = vals.split(' ');
    switch (allVals.length) {
        case 1:
        case 2:
            return allVals[0];
        default:
            return allVals[2];
    }
};

const getRightValue = (vals: string): string => {
    const allVals = vals.split(' ');
    switch (allVals.length) {
        case 1:
            return allVals[0];
        default:
            return allVals[1];
    }
};

const getLeftValue = (vals: string): string => {
    const allVals = vals.split(' ');
    switch (allVals.length) {
        case 1:
            return allVals[0];
        case 2:
        case 3:
            return allVals[1];
        default:
            return allVals[3];
    }
};

// For margin-top, label is usually above the input so the margin-top comes from labelMargins
const FormCheckboxWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top:
        ${({ theme }): string => {
            const margin = theme?.formTheme?.labelMargins;
            return margin ? getTopValue(margin) : '0';
        }};
    margin-right:
        ${({ theme }): string => {
            const margin = theme?.formTheme?.inputMargins;
            return margin ? getRightValue(margin) : '0';
        }};
    margin-bottom:
        ${({ theme }): string => {
            const margin = theme?.formTheme?.inputMargins;
            return margin ? getBottomValue(margin) : '6px';
        }};
    margin-left:
        ${({ theme }): string => {
            const margin = theme?.formTheme?.inputMargins;
            return margin ? getLeftValue(margin) : '0';
        }};
`;

const CheckboxLabel = styled(FormLabel)`
    flex: 1;
    margin: 0 0 0 6px;
`;

const FormCheckbox = styled.input`
    flex: 0;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    padding: 0;
`;

const componentName = 'Checkbox';

type CheckboxProps = {
    id: string;
    options: CheckboxOptions;
    macroContext: MacroContext;
};

const Checkbox = ({
    id,
    options,
    macroContext,
}: CheckboxProps): React.ReactElement | null => {
    const scope = useMacroScope(macroContext);

    const label = RuntimePromise.unwrapOr(
        '',
        useMacro(
            options.label ?? '',
            scope,
            {
                componentName,
                optionName: 'label',
            },
        ),
    );

    const form = useSelector((state: State) => getForm(state, macroContext.formId ?? '-1'));
    const value = form?.data[options.name] ?? RuntimePromise.Success(!!options.defaultValue);

    if (RuntimePromise.logUnresolved(value, componentName)) {
        throw new Error(`error resolving ${componentName}`);
    }
    const unwrappedValue = RuntimePromise.unwrap(value);

    const dispatch = useDispatch();
    const setValue = React.useCallback(
        (val: boolean): void => {
            if (form) {
                dispatch(formSetAttribute(
                    form.id,
                    options.name,
                    RuntimePromise.Success(val),
                    options.formValidations,
                ));
            }
        },
        [dispatch, form, options.formValidations, options.name],
    );

    React.useEffect(() => {
        if (form?.status === FORM_STATUS_SETUP) {
            setValue(options.defaultValue ?? false);
        }
    }, [options.defaultValue, form, setValue]);

    const htmlId = React.useMemo(() => `input-${id}`, [id]);
    return (
        <FormCheckboxWrapper>
            <div>
                <FormCheckbox
                    id={htmlId}
                    type="checkbox"
                    name={options.name}
                    checked={Boolean(unwrappedValue)}
                    onChange={(e): void => setValue(e.target.checked)}
                />
            </div>
            <CheckboxLabel
                htmlFor={htmlId}
            >
                {label}
            </CheckboxLabel>
        </FormCheckboxWrapper>
    );
};

export default Checkbox;
