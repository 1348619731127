import React, { useMemo } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import GlobalStyles from './globalStyles';
import {
    StudioFile,
    getLoadableState,
    AssetAssociation,
    getAssetAssociations,
    urlForFile,
} from './store/processes/studioFile';
import {
    COOKIE_BANNER_THEME_LIGHT,
} from './store/processes/studioFile/componentConfigs/cookieBanner';
import { TranslationProvider } from './translations';
import ComponentList from './components/shared/componentList';
import * as LoadableState from './store/processes/loadableState';
import Missing from './missing';
import { SDKContextProvider } from './sdkContext';

const ExperienceBody = styled.div`
    padding: ${({ theme }): string => theme.bodyPadding};
    margin: 0 auto;
    max-width: ${({ theme }): string => theme.maxWidth};
    overflow: hidden;
`;

export const makeTheme = (
    studioFile: StudioFile,
    assetAssociations: Array<AssetAssociation>,
): DefaultTheme => ({
    fontRegular: '\'Roboto\', sans-serif',
    backgroundColor: studioFile.attributes.backgroundColor ?? '#eeeeee',
    backgroundImage: (
        studioFile.attributes.backgroundImage
            ? `url("${
                urlForFile(
                    studioFile.attributes.backgroundImage,
                    assetAssociations,
                )
            }")`
            : 'none'
    ),
    bodyPadding: (
        studioFile.attributes.componentStyle === 'default'
            ? '16px'
            : '0'
    ),
    componentMargin: (
        studioFile.attributes.componentStyle === 'default'
            ? '0 0 12px'
            : '0'
    ),
    componentBorder: (
        studioFile.attributes.componentStyle === 'default'
            ? '1px solid rgba(0,0,0,.15)'
            : 'none'
    ),
    componentBorderRadius: (
        studioFile.attributes.componentStyle === 'default'
            ? '2.5px'
            : 'none'
    ),
    componentPadding: '16px',
    maxWidth: '480px',
    showWidget: false,
    cookieBannerTheme: COOKIE_BANNER_THEME_LIGHT,
});

const Experience: React.ComponentType<Record<string, never>> = () => {
    const loadableStudioFile = useSelector(getLoadableState);
    const assetAssociations = useSelector(getAssetAssociations);

    const theme = useMemo(
        () => (
            LoadableState.isReady(loadableStudioFile)
            ? makeTheme(
                LoadableState.unwrap(loadableStudioFile),
                assetAssociations,
            )
            : null
        ),
        [assetAssociations, loadableStudioFile],
    );

    if (
        LoadableState.isLoading(loadableStudioFile)
        || LoadableState.isUninitialized(loadableStudioFile)
    ) {
        return null;
    }

    if (LoadableState.isError(loadableStudioFile)) {
        return <Missing />;
    }

    const studioFile = LoadableState.unwrap(loadableStudioFile);

    if (!studioFile || !theme) { return null; }

    return (
        <SDKContextProvider>
            <GlobalStyles theme={theme}>
                <TranslationProvider>
                    <ExperienceBody>
                        <ComponentList id="0" />
                    </ExperienceBody>
                </TranslationProvider>
            </GlobalStyles>
        </SDKContextProvider>
    );
};

export default Experience;
