import * as R from 'ramda';
import memoize from 'fast-memoize';
import { createSelector } from 'reselect';
import { State, Selector } from '../index';
import {
    StudioComponentConfig,
    StudioComponentSharedConfig,
    StudioComponentTypeId,
} from './components';
import * as LoadableState from '../loadableState';
import { cleanLink } from '../../../functions/clean';

export type StudioFile = {
    id: string;
    version: string;
    attributes: ExperienceAttributes;
    relationships: ExperienceRelationships;
    assets: AssetMappings;
    asset_associations: Array<AssetAssociation>;
};

export const ASSET_TYPE_IMAGE = 'IMAGE' as const;

export type AssetType = typeof ASSET_TYPE_IMAGE;

export type AssetAssociation = {
    assetType: AssetType;
    assetId: string;
    assetVersionId: string;
    filename: string;
};

export type AssetReference = {
    type: 'ASSET_REFERENCE';
    assetId: string;
};

export type FileReference = AssetReference | string | null;

export type Asset = {
    hashed_filename: string;
    content?: string;
};

export type AssetMappings = { [key: string]: Asset };

export const ANALYTICS_TYPE_GA = 'ga' as const;
export const ANALYTICS_TYPE_NONE = 'none' as const;

export type AnalyticsType =
    | typeof ANALYTICS_TYPE_GA
    | typeof ANALYTICS_TYPE_NONE;

export type Dimension = { key: string; value: string };

export type AnalyticsIds = string | string[];

export type ExperienceAttributes = {
    componentStyle?: 'default' | 'none';
    backgroundColor?: string;
    backgroundImage?: string;
    trackingId?: AnalyticsIds;
    analyticsType?: AnalyticsType;
    gaDimensions?: Array<Dimension>;
};

export type ComponentList = {
    id: string;
    items: Array<StudioComponentSharedConfig>;
    // These keys currently exists but are deprecated
    // componentTypeId: string;
    // componentKey: string;
};

export type ExperienceComponentRelationships = {
    items: Array<StudioComponentSharedConfig>;
    lists?: Array<ComponentList>;
    nextId: number;
    nextListId?: number;
};

export type ExperienceRelationships = {
    components: ExperienceComponentRelationships;
    customComponentVersions: Record<string, string>;
};

export const EVENT_TYPE_FORM_SUBMIT = 'formSubmit';
export const EVENT_WHEN_AFTER = 'after';

export const FORM_TYPE_ID = '21';
export const EVENT_TYPE_ID = '25';

export const UPDATE_STUDIO_FILE = 'STUDIO_FILE/UPDATE' as const;

export type UpdateStudioFileAction = {
    type: typeof UPDATE_STUDIO_FILE;
    update: LocalState;
};

export const updateStudioFile = (
    update: LocalState,
): UpdateStudioFileAction => ({
    type: UPDATE_STUDIO_FILE,
    update,
});

type Action = UpdateStudioFileAction | { type: 'FALLBACK' };

export type LocalState = LoadableState.Type<StudioFile, string>;

const initialState = LoadableState.Loading;

const reducer = (
    state: LocalState = initialState,
    action: Action,
): LocalState => {
    switch (action.type) {
        case UPDATE_STUDIO_FILE:
            return action.update;
        default:
            return state;
    }
};

export const getLoadableState = (
    state: State,
): LoadableState.Type<StudioFile, string> => state.studioFile;
export const getStudioFile = (state: State): StudioFile | null => (
    LoadableState.unwrapOr(null, state.studioFile)
);
export const getStudioFileId = (state: State): string | null => (
    getStudioFile(state)?.id ?? null
);
export const getStudioFileAttribute: {
    <K extends keyof ExperienceAttributes>(name: K, state: State): ExperienceAttributes[K] | null;
    <K extends keyof ExperienceAttributes>(name: K):
        (state: State) => ExperienceAttributes[K] | null;
} = R.curry(<K extends keyof ExperienceAttributes>(
    name: K,
    state: State,
): ExperienceAttributes[K] | null => (
    getStudioFile(state)?.attributes?.[name] ?? null
)) as any;
export const getAssetAssociations = (state: State): Array<AssetAssociation> => (
    getStudioFile(state)?.asset_associations || []
);
export const getAnalyticsIsOn = (state: State): boolean => (
    (!__IS_PREVIEW__)
    && getStudioFileAttribute('analyticsType', state) === 'ga'
);
export const getCustomComponentLatestVersion = (uuid: string) => (
    state: State,
): string => (
    (getStudioFile(state)?.relationships.customComponentVersions ?? {})[uuid]
);
export const getRootComponents = (
    state: State,
): Array<StudioComponentSharedConfig> => (
    getStudioFile(state)?.relationships.components.items ?? []
);
export const getComponentListItems = (listId: string) => (
    (state: State): Array<StudioComponentSharedConfig> => {
        const studioFile = getStudioFile(state);
        if (!studioFile) { return []; }
        const { components } = studioFile.relationships;
        if (listId === '0') { return components.items; }
        const list = (components.lists ?? []).find(({ id }) => id === listId);
        return list ? list.items : [];
    }
);
export const getComponentLists = (state: State): Array<ComponentList> => (
    getStudioFile(state)?.relationships.components.lists ?? []
);
export const getAssets = (state: State): AssetMappings | null => (
    getStudioFile(state)?.assets ?? null
);
export const getAllComponents = createSelector(
    getRootComponents,
    getComponentLists,
    (rootComponents, componentLists): Array<StudioComponentSharedConfig> => [
        ...rootComponents ?? [],
        ...R.unnest(R.map(R.prop('items'), componentLists ?? [])),
    ],
);

export const getComponentLookupTable = createSelector(
    getAllComponents,
    (allComponents): { [key: string]: StudioComponentSharedConfig } => (
        R.fromPairs(R.map((c) => [c.id, c], allComponents))
    ),
);
export const getComponentById = (id: string) => (
    (state: State): StudioComponentSharedConfig | null => (
        getComponentLookupTable(state)[id] ?? null
    )
);

export const getListLookupTable = createSelector(
    getComponentLists,
    (componentLists) => R.fromPairs(R.map((l) => [l.id, l], componentLists)),
);
export const getListById = (id: string) => (
    (state: State): ComponentList | null => (
        getListLookupTable(state)[id] ?? null
    )
);

// Custom recursive caching
export const getChildrenDeep = (
    (): ((id0: string) => (state: State) => Array<StudioComponentSharedConfig>) => {
        let lastState: State | null = null;
        let selector: (
            ((id: string) => Array<StudioComponentSharedConfig>) | null
        ) = null;

        return (id0: string) => (
            (state: State): Array<StudioComponentSharedConfig> => {
                if (lastState !== state || !selector) {
                    lastState = state;
                    selector = memoize((id1) => {
                        const component = getComponentById(id1)(state);
                        return R.unnest(R.map(
                            (listId) => {
                                const listItems = (
                                    getListById(listId)(state)?.items
                                    || []
                                );
                                return [
                                    ...listItems,
                                    ...R.unnest(R.map(
                                        ({ id }) => getChildrenDeep(id)(state),
                                        listItems,
                                    )),
                                ];
                            },
                            R.values(component?.componentLists ?? {}),
                        ));
                    });
                }
                return selector(id0);
            }
        );
    }
)();

export const getComponentsParentsListIdLookupTable = createSelector(
    getComponentLists,
    getRootComponents,
    (componentLists, rootComponents): { [key: string]: string } => R.fromPairs([
        ...R.unnest(R.map(
            ({ id, items }): Array<[string, string]> => (
                R.map((item) => [item.id, id], items)
            ),
            componentLists,
        )) as Array<[string, string]>,
        ...(
            R.map(
                (item) => [item.id, '0'],
                rootComponents,
            ) as Array<[string, string]>
        ),
    ]),
);
export const getComponentsParentsListId = (id: string) => (
    (state: State): string | null => (
        getComponentsParentsListIdLookupTable(state)[id] ?? null
    )
);

export const getListsParentsComponentIdLookupTable = createSelector(
    getComponentLookupTable,
    R.pipe(
        R.toPairs,
        R.map(([id, component]: [string, StudioComponentSharedConfig]) => R.map(
            (k): [string, string] => [k, id],
            R.values(component.componentLists ?? {}),
        )),
        R.unnest,
        R.fromPairs as (
            pairs: Array<[string, string]>,
        ) => { [key: string]: string },
    ),
);
export const getListsParentsComponentId = (id: string) => (
    (state: State): string | null => (
        getListsParentsComponentIdLookupTable(state)[id] ?? null
    )
);

export const getParentComponent = R.curry((
    id: string,
    state: State,
): StudioComponentSharedConfig | null => {
    const lid = getComponentsParentsListId(id)(state);
    if (lid === null) { return null; }
    const pid = getListsParentsComponentId(lid)(state);
    if (pid === null) { return null; }
    return getComponentById(pid)(state);
});

const getParentWithType = <T extends StudioComponentTypeId>(typeId: T) => (
    (id: string, state: State): StudioComponentConfig<T> | null => {
        let itt = getParentComponent(id)(state);

        while (itt) {
            if (itt.typeId === typeId) { return itt as StudioComponentConfig<T>; }
            itt = getParentComponent(itt.id)(state);
        }

        return null;
    }
);

export const getParentForm = getParentWithType(FORM_TYPE_ID);
export const getParentEvent = getParentWithType(EVENT_TYPE_ID);

export const getAllComponentsOfType = (
    typeId: string,
): Selector<Array<StudioComponentSharedConfig>> => (
    createSelector(
        getAllComponents,
        R.filter(R.propEq('typeId', typeId)),
    )
);

export const getAllForms = getAllComponentsOfType(FORM_TYPE_ID);

export const urlForAssociation = (a: AssetAssociation | null): string => [
    __ASSOCIATED_ASSETS_BASE__,
    '/',
    a ? a.assetId : '',
    '/',
    a ? a.filename : '',
].join('');

export const urlForFile = (
    file: FileReference,
    associations: Array<AssetAssociation>,
): string => (
    typeof file === 'string'
        ? cleanLink(file)
        : (
            file
            && typeof file === 'object'
            && file.type === 'ASSET_REFERENCE'
                ? urlForAssociation(
                    associations.find((a) => a.assetId === file.assetId) ?? null,
                )
                : ''
        )
);

export default reducer;
